






































































import { Component, Mixins, Prop } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { fetchSingleOrder } from '~/api/order'
import { DynamicClassesOnShow } from '~/mixins/dynamic-classes-on-show'
import { AuthStore } from '~/store/auth'
import { BeatStore } from '~/store/beat'
import { BASE_URL } from '~/const/base-url'
import CartHeading from '@/components/cart/CartHeading.vue'
import { IS_MARKETPLACE, IS_ELITEPAGE } from '~/const/environment'

@Component({ components: { CartHeading, VLoadSpinner } })
export default class OrderReviewPage extends Mixins(DynamicClassesOnShow) {
    @Prop({ type: String }) email!: string
    @Prop({ type: Number }) orderId!: number

    BASE_URL = BASE_URL
    IS_MARKETPLACE = IS_MARKETPLACE
    IS_ELITEPAGE = IS_ELITEPAGE
    isLoading = false
    order: IOrderDataSimple = null

    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
    @BeatStore.Action('openPdfContact') openPdfContact: ({ beatId, email }: { beatId: number; email: string }) => void

    get fullScreenOnSmallOrders() {
        return this.order?.bought_beats.length <= 5 ? 'section--fullScreen section--flexCenter' : 'sectionCart'
    }

    async fetchEmailOrderData() {
        this.isLoading = true
        try {
            this.order = await fetchSingleOrder({ email: this.email, orderId: this.orderId })
        } finally {
            this.isLoading = false
        }
    }

    mounted() {
        this.fetchEmailOrderData()
    }
}
